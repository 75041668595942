import React from 'react';
import SimplePaper from './Components/Paper';
import { Security, LoginCallback, SecureRoute } from '@okta/okta-react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import './App.css';

/// for flex prod    
// const config = {
//   issuer: process.env.REACT_APP_OKTA_ORG_URL,
//   redirectUri: window.location.origin + '/implicit/callback',
//   clientId: process.env.REACT_APP_OKTA_CLIENT_ID,
//   scopes: ["openid", "email", "profile"],
//   pkce: true
// };


const config = {
  issuer: 'https://flex.okta.com',
  redirectUri: window.location.origin + '/implicit/callback',
  //  redirectUri: window.location.origin + '/login/callback',
  clientId: '0oa8qv3ajrpTAlP2K2p7',
  scopes: ["openid", "email", "profile"],
  pkce: true
};

/// for flex dev    
//  const config = {
//   issuer: 'https://flexdev.oktapreview.com/oauth2/default',
//   redirectUri: window.location.origin + '/implicit/callback',
//   clientId: '0oaur4kzxwj6ysEFx0h7',
//   pkce: true
// };


/* const config = {
  issuer: 'https://dev-798942.okta.com/oauth2/default',
  redirectUri: window.location.origin + '/implicit/callback',
  clientId: '0oa5x2b2v4qiK3W5C357',
  pkce: true
}; */


function App() {
  return (
    <Router>
      <Security {...config}>
        <SecureRoute path='/' exact={true} component={SimplePaper} />
        <Route path='/implicit/callback' component={LoginCallback} />
      </Security>
    </Router>
  )
}

export default App;
